@import "../../Globals";

.badge-container {
  position: relative;
  display: inline-block;
}

.badge {
  z-index: 1;
  position: absolute;
  top: -$leading;
  right: $leading;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: $font_size * 0.8;
  padding: 0 $leading;
  border: 1px solid white;
  transform: translate(100%, 0);
  white-space: nowrap;
  min-height: 1.5em;
}

.badge-flat {
  border-radius: 3px;
}

.badge-circle {
  min-width: $line_height;
  min-height: $line_height;
  border-radius: 50%;
  padding: 0;
}
