.page-turner {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1500px;

  .book {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-1px); /* Center the book */
  }

  .page {
    position: absolute;
    width: 50%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.8s ease;
    transform-origin: center left;
    opacity: 0;
    z-index: 1;

    &.visible {
      opacity: 1;
    }

    &:nth-child(even) {
      left: 50%;
      transform-origin: center right;
    }

    &:nth-child(odd) {
      left: 0;
      transform: rotateY(0);
    }

    &.visible:nth-child(odd) {
      transform: rotateY(0);
    }

    &.visible:nth-child(even) {
      transform: rotateY(-180deg);
    }
  }

  .portrait .page {
    width: 100%;
  }

  .controls {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .prev,
    .next {
      cursor: pointer;
      padding: 10px;
    }
  }
}
