body, html {
  position: fixed;
  overflow: hidden;
  touch-action: manipulation;
}


.passthrough {
  pointer-events: none;

  .card, .Panel, .tooltip-container, .popover-container, button {
    pointer-events: initial;
  }
}