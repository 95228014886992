@import "Globals.scss";

* {
  box-sizing: border-box;
  // letter-spacing: 0.052em;
  position: relative;
  padding: 0;
  margin: 0;
}

body, html {
  position: fixed;
  overflow: hidden;
  touch-action: manipulation;
  width: 100%;
}

body canvas.fixed {
  touch-action: none;
}

.space.gap, .row.gap {
  //box-sizing: content-box;
}

.no-select {
  user-select: none;
}

small, .small {
  font-size: 80%;
}

// FONTS

p, span, .text {
  &.error {
    color: theme-color(danger_color);
  }
}

.input, textarea {
  &.error {
    border-color: theme-color(danger_border);
    //color: theme-color(danger_border);
  }
}

ul {
  margin-left: 1em;
  list-style-type: square;
}
ol {
  margin-left: $box_padding;
}

/* Style inputs */
button, input, .input,
.button,
input[type=text],
input[type=email],
input[type=password],
.tiptap,
textarea {

  display: inline-block;
  background-color: theme-color(input_background);
  color: theme-color(text);
  padding: $leading $line_height;
  outline: 0;
  height: fit-content;
  // width: 100%;
  border: 1px solid theme-color(border_color);
  //white-space: pre;
  margin: 0;
  border-radius: $border_radius;

}

.paragraph, p, li, small {
  color: theme-color(text);
  &.no-wrap {
    white-space: pre;
  }
}

li, small, span, svg {
  color: inherit;
}

span, svg {

  &.primary {
    // background-color: theme-color(primary_color);
    color: theme-color(primary_text);
    // border: 1px solid theme-color(primary_border);

    &:hover {
      // background-color: darken(theme-color(primary_color), 10%);
    }
  }

  &.ghost {
    //  background-color: theme-color(ghost_color);
    color: theme-color(ghost_text);
    //border: 1px solid theme-color(ghost_border);

    &:hover {
      // background-color: lighten(theme-color(ghost_color), 10%);
    }
  }

  &.danger {
    //  background-color: theme-color(danger_color);
    color: theme-color(danger_color);
    //  border: 1px solid theme-color(danger_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.success {
    // background-color: theme-color(warning_color);
    color: theme-color(success_text);
    //  border: 1px solid theme-color(warning_border);

    &:hover {
      // background-color: darken(theme-color(warning_color), 10%);
    }
  }
  &.warning {
    // background-color: theme-color(warning_color);
    color: theme-color(warning_color);
    //  border: 1px solid theme-color(warning_border);

    &:hover {
      // background-color: darken(theme-color(warning_color), 10%);
    }
  }

  &.like {
    // background-color: theme-color(like_color);
    color: theme-color(like_text);
    // border: 1px solid theme-color(like_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.link {
    // background-color: transparent;
    color: theme-color(link_text);
    //  border: 1px solid theme-color(link_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.disabled, &:disabled {
    // background-color: theme-color(disabled_bg);
    color: theme-color(disabled_text);
    cursor: not-allowed;
    // border: 1px solid theme-color(disabled_border);

    &:hover {
      // background-color: theme-color(disabled_bg);
    }
  }
}


/* Style buttons */
button, .button {
  &:hover {
    cursor: pointer;
    background-color: (theme-color(input_background));
  }
}

button, .button, .pill, .card {

  &.small {
    padding: $leading/2 $line_height;
  }


  &.active, &.is-active {
    color: theme-color(active_text);
    background-color: theme-color(active_background);
    border: 1px solid theme-color(active_text);

    &:hover {
      //background-color: darken(theme-color(active_background), 10%);
    }
  }

  &.primary {
    background-color: theme-color(primary_color);
    color: theme-color(primary_text);
    border: 1px solid theme-color(primary_border);

    &:hover {
      // background-color: darken(theme-color(primary_color), 10%);
    }
  }

  &.ghost {
    background-color: theme-color(ghost_color);
    color: theme-color(ghost_text);
    border: 1px solid theme-color(ghost_border);

    &:hover {
      // background-color: lighten(theme-color(ghost_color), 10%);
    }
  }

  &.danger {
    background-color: theme-color(danger_color);
    color: theme-color(danger_text);
    border: 1px solid theme-color(danger_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.warning {
    background-color: theme-color(warning_color);
    color: theme-color(warning_text);
    border: 1px solid theme-color(warning_border);

    &:hover {
      // background-color: darken(theme-color(warning_color), 10%);
    }
  }

  &.like {
    background-color: theme-color(like_color);
    color: theme-color(like_text);
    border: 1px solid theme-color(like_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.link {
    background-color: transparent;
    color: theme-color(link_text);
    border: 1px solid theme-color(link_border);

    &:hover {
      // background-color: darken(theme-color(danger_color), 10%);
    }
  }

  &.disabled, &:disabled, &.empty {
    background-color: theme-color(disabled_background);
    color: theme-color(disabled_text);
    cursor: not-allowed;
    border: 1px solid theme-color(disabled_border);

    &:hover {
      // background-color: theme-color(disabled_background);
    }
  }
}


.full {
 // height: -webkit-fill-available;
  //height: 100%;
  min-height: 100%;
  display: flex;
 // max-height: 100%;
}

.card.full {
  height: 100%;
}

.content.full {
  max-height: 100%;
}

.row.full {
  //min-height: -webkit-fill-available;
  //max-height: 100%;
}


.hero {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #f0f2f5;
  background: linear-gradient(135deg, #2578e0, #15ccc6);
  box-shadow: 4px 4px 1px rgba(1,1,1,0.05);

  color: #fff;

  border-radius: $border_radius;

  img {
    width: 100%;
  }


  &.alt {
    background: linear-gradient(135deg, rgba(83, 217, 94, 0.57), rgb(209, 231, 22));
    color: initial;
  }
  &.altb {
    background: linear-gradient(135deg, rgb(255, 222, 166), rgba(255, 74, 184, 0.23));
    color: initial;
  }

}

.muted {
  opacity: 0.6;
}
.muted-heavy {
  opacity: 0.3;
}

.title.lg, .huge .lg, .huge.lg{
  font-size: $font_size * 3;
  font-weight: 100;
  line-height: 125%;
}

@media (max-width: 576px) {
  .title.lg, .huge .lg, .huge.lg {
    font-size: $font_size * 2.5 ;
  }
}


.block-warning.huge {
  //font-size: 150%;
  //line-height: 100%;
  //padding: $content_padding/2;
}


.block, .wide {
  //display: block;
  width: 100%;
}

.divider {
  border-bottom: 1px solid #ccc;
  padding-top: 7px;
  margin-bottom: 7px;
  flex-grow: 1;
}

blockquote {
  padding: 4pt 8pt;
  background-color: theme-color(quote_color);
  color: theme-color(quote_text);
  border-radius: $border_radius;
  border: 1px solid theme-color(quote_border);
}


// FONTS
body, p, a, input {
  font-family: $content_font;
  font-size: $font_size;
  //line-height: $line_height;
}

h1, h2, h3, h4, h5 {
  //line-height: $line_height;
  // font-size: $font_size*1.2;
  font-family: $header_font;
  color: theme-color(text);
  //font-weight: 400;
}

h1 {
  font-size: $font-size*2;
}

span, button, small {
  font-size: inherit;
}

.heavy {
  font-weight: bolder;
}


.bottom-gradient {
  padding-bottom: 80px;
  position: relative; // Ensure the gradient overlay is positioned correctly

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(213, 226, 232, 0.18) 100%);
    pointer-events: none; // Ensures the gradient doesn't interfere with user interactions
  }
}


.inline-box, .inline-icon {
  height: 0.75em;
  width: 0.75em;
  background-color: theme-color(highlight_text);
  display: inline-block;

  &.circle {
    border-radius: 50%;
  }
}

.border-box {
  padding: $leading/2;
  display: inline-block;
}

.comment {
  background-color: theme-color(comment_color);
  color: theme-color(comment_text);
  //padding: $leading;
  //border: 1px solid theme-color(comment_border);
  //border-radius: $border_radius;
}


body, .Paragraph, p {
  line-height: 1.35;
}

.client-logo {
  max-height: 30px;
}

a:hover, .link:hover {
  cursor: pointer;
}


.abs {
  width: 100%;
  position: absolute;
  top: 0;
}



.table_wrapper {
  border: 1px solid theme-color(table_border);
  overflow: hidden;
  border-radius: $border_radius;
}

table {
  font-family: "Source Code Pro", "Calling Code", "Tahoma", monospace;
  border-radius: $border_radius;
  width: 100%;
  border: none;
  border-collapse: collapse;
  font-size: $font_size * 0.8;
}

table, th, td {
  //border: 1px solid #ccc;
  line-height: $leading;
  table-layout: fixed;
}

th, td {
  text-align: left;
  padding: $leading;
}

th {
  background-color: theme-color(table_th);
  color: theme-color(table_th_text);
}


tr {
  background-color: theme-color(table_td);
  color: theme-color(table_td_text);
}

tr:nth-child(even) {
  // background-color: darken(theme-color(table_td),1%);
}

tr:hover {
  //background-color: lighten(theme-color(table_td),1%);
}
