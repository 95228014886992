@import "../../Globals";

.grow {
  //flex-grow: 1;
  position: relative;
  flex: 1 1 auto;
  //height: 100%;
}

.no-scroll {
  overflow: hidden !important;
}

.PointerEvents, .panel, .button, header, footer, select {
  pointer-events: all;
}

footer {
  &.dark {
    background-color: theme-color(background_dark);
    p {
      color: theme-color(text_dark);
    }
    strong {
      //color: theme-color(highlight_text)
    }
  }
}

.flex {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }

}

.flex, .row, .space, .content {

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: end;
  }
}

.space.no-wrap {
  //white-space: nowrap;
  flex-wrap: nowrap;
}

.page {
  overflow-y: initial;
  background-color: theme-color(background);

  &.grow {
    overflow-y: auto;
  }

  .content {
    // min-height: 100%;
    //overflow: auto;
    margin: 0 auto;
    position: relative;
  }
}

#root {
  //position: fixed;
  //height: 100svh;
  //overflow-y: hidden;
}

.layout {
  height: 100dvh;
  display: flex;
  flex-direction: column;

  //height: 100lvh;
  &.fixed {
    display: inline-flex;
    top: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  background-color: theme-color(background);
}

.banner {
  background-image: url("//peek.blog/site-banner.png");
  background-size: auto 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (max-width: breakpoint('md') - 1px) {
  .banner {
    background-image: url("//peek.blog/site-banner-mobile.png");
  }
}


/* Make Content fill available space */
.content {
  //flex: 1;
  //display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.limits {
  width: 100%;
  max-width: breakpoint('xl');
  margin: 0 auto;

  &.wide {
    max-width: breakpoint('xls');
  }
  &-mobile {
    max-width: breakpoint('md');
  }
}


.overflow-ellipsis {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pad {
  padding: $box_padding;

  &-sm {
    padding: $box_padding / 2;
  }
  &-lg {
    padding: $box_padding * 2;
  }

  &-v {
    padding-top: $box_padding;
    padding-bottom: $box_padding;
  }

  &-top {
    padding-top: $box_padding;
  }

  &-bottom {
    padding-bottom: $box_padding;
  }

  &-h {
    padding-left: $box_padding;
    padding-right: $box_padding;
  }

  &-content {
    padding: 0 $box_padding;
  }
}

.wide {
  width: 100%;
  max-width: initial;
}

.internalScrollContainer {
  //width: fit-content;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  pointer-events: all;
  //row-gap: 7pt;
}


///////////
.space {
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
}

// Layout.scss
.space, .row, .col, .card {


  &.space-horizontal {
    display: flex;
    flex-direction: row;
    // align-items: center;
    //row-gap: calc( $gap_sizing / 2);
  }

  &.space-vertical {
    display: flex;
    flex-direction: column;
  }


  &.justify-start {
    justify-content: start;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }


  &.align-top {
    align-items: start;
  }

  &.align-middle {
    align-items: center;
  }

  &.align-bottom {
    align-items: end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.align-center {
    align-items: center;
  }

  &.align-right {
    display: flex;
    flex-direction: row-reverse;
  }

}

.card {
  background-color: theme-color(card_background);
  border-radius: $border_radius;
  border: 1px solid theme-color(border_color);

  &.active {
    border-color: theme-color(primary_color);
    background-color: theme-color(active_background);
  }

  &.disabled {
    opacity: 0.6;
    background-color: theme-color(input_background);
  }
}



.input-wrapper {
  position: relative;
  display: inline-grid;

  .input, textarea {

    &.error {
      border-color: theme-color(danger_border);
      //color: theme-color(danger_border);
    }

  }



  .error-icon {
    position: absolute;
    top: $leading;
    right: 10px;
   // transform: translateY(-50%);
    color: red;
   // pointer-events: none;
  }

}

.content svg.error {
  color: theme-color(danger_color);
}

.content svg.success {
  color: theme-color(primary_color);
}


.announcement {
  border-radius: $border_radius;
  text-shadow: 2px 2px 3px white;
  border: 1px solid theme-color(announce_border);

  background: repeating-linear-gradient(35deg,
          theme-color(announce_background_stripe1),
          theme-color(announce_background_stripe1) 20px,
          theme-color(announce_background_stripe2) 10px,
          theme-color(announce_background_stripe2) 40px);
  color: #38342f;

  .icon {
    cursor: pointer;
    //border:1px solid black;
  }

  span {
    a {
      // color: #ff0073;
      font-weight: 600;
      text-decoration: underline;
    }
  }

}


.block-warning {
  //  text-shadow: 2px 2px 3px white;
  border-left: 0.5em solid theme-color(announce_warning_border);
  position: relative;
  left: -$box_padding;

  background: repeating-linear-gradient(35deg,
          theme-color(announce_warning_background_stripe1),
          theme-color(announce_warning_background_stripe1) 20px,
          theme-color(announce_warning_background_stripe2) 10px,
          theme-color(announce_warning_background_stripe2) 40px);
  color: #38342f;

  .icon {
    cursor: pointer;
    //border:1px solid black;
  }

  span {
    a {
      // color: #ff0073;
      font-weight: 600;
      text-decoration: underline;
    }
  }

}

.frame {
  padding: $leading*4 $leading;
  //height: 80vh;
  width: 100%;

  &.card {
    background-color: theme-color(input_background);
  }
}

.stretch {
  > * {
    flex: 1 1 auto;
  }
}

.layout {

  header {
    padding: $content_padding $content_padding $leading $content_padding;
  }

  footer {
    padding: $leading $content_padding $content_padding $content_padding;
  }
}

.App {
  &.mobile, &.tablet {
    .layout {
      header {
        padding: $box_padding $box_padding $leading $box_padding;
      }

      footer {
        padding: $leading $box_padding $box_padding $box_padding;
      }
    }
  }

  &.mobile.ios.pwa {
    footer {
      padding-bottom: $leading*4;
    }
  }

  &.mobile.ios.pwa {
    header {
      padding-top: 0;
    }
  }

}

.dropdown-container {
  display: inline;
}

/* Dropdown inner style */
.dropdown-inner {
  z-index: 1;
  background-color: theme-color(card_background);
  position: absolute;
  display: none;
  right: 0;
  top: $line_height * 2.5;
  min-width: 200px;
  width: max-content;
  overflow: hidden;
  border: 1px solid theme-color(border_color);
  padding: $box_padding;
  border-radius: $border_radius;
  &.left {
    right: auto;
    left: 0;
    top: $line_height *2;
  }
}


.dropdown-inner a {
  text-decoration: none;
}

.dropdown-inner.active a:hover .content, li.active {
  text-decoration: underline;
}

/* Dropdown inner style when active */
.dropdown-inner.active {
  display: block;
}


ol li {
  margin-bottom: $leading;
}