@function get-color($color-name, $weight) {
  @return map-get(map-get($COLORDEF, $color-name), $weight);
}

// Typography
$header_font: 'Inclusive Sans', serif;
$content_font: 'Inclusive Sans', sans-serif;
$fancy_font: 'Caveat', cursive;
$code_font: monospace;


// Line heights, gutter
$font_size: 11pt;
$line_height: 15pt;
$leading: calc($line_height / 2);
$gap_sizing: calc($line_height);
$box_padding: $line_height;
$content_padding: $line_height * 2.5;

$border_radius: 4pt;

$COLORDEF: (
        'PRIMARY':(
                DEFAULT: #3182ce,
                100: #0a1a29, 200: #133453, 300: #1d4e7c, 400: #2768a5, 500: #3182ce, 600: #5a9bd8, 700: #83b4e2, 800: #accdec, 900: #d6e6f5
        ),
        'Ash_Gray': (DEFAULT: #b5c9c3,
                100: #202c29, 200: #405951, 300: #60857a, 400: #89a9a0, 500: #b5c9c3, 600: #c4d4cf, 700: #d3dfdb, 800: #e1eae7, 900: #f0f4f3
        ),
        'Bittersweet': (
                DEFAULT: #eb5e55,
                100: #3a0a07, 200: #73140d, 300: #ad1e14, 400: #e42a1d, 500: #eb5e55, 600: #ef8078, 700: #f3a09a, 800: #f7bfbb, 900: #fbdfdd
        ),
        'Jet': (
                DEFAULT: #3a3335,
                100: #0b0a0a, 200: #171415, 300: #221e1f, 400: #2d282a, 500: #3a3335, 600: #63585b, 700: #8d7f83, 800: #b3aaac, 900: #d9d4d6
        ),
        'lavender': (
                DEFAULT: #c287e8,
                100: #2a0c3e, 200: #53187b, 300: #7d23b9, 400: #a249dc, 500: #c287e8, 600: #ce9fed, 700: #dab7f1, 800: #e6cff6, 900: #f3e7fa
        ),
        'light_green': (
                DEFAULT: #8ff7a7,
                100: #054815, 200: #0a912a, 300: #0fd93e, 400: #44f26d, 500: #8ff7a7, 600: #a3f9b7, 700: #bafac9, 800: #d1fcdb, 900: #e8fded
        ),
        'maize': (
                DEFAULT: #f4e76e,
                100: #413b05, 200: #83770a, 300: #c4b20f, 400: #efdb2b, 500: #f4e76e, 600: #f6eb89, 700: #f8f0a7, 800: #fbf5c4, 900: #fdfae2
        ),
        'maya_blue': (
                DEFAULT: #51bbfe, 100: #002943, 200: #015386, 300: #017cc9, 400: #0fa2fe, 500: #51bbfe, 600: #75cafe, 700: #97d7fe, 800: #bae4ff, 900: #dcf2ff
        ),
        'mimi_pink': (
                DEFAULT: #eecfd4,
                100: #42171e, 200: #832e3c, 300: #c04b5e, 400: #d78c99, 500: #eecfd4, 600: #f1d8dc, 700: #f5e2e5, 800: #f8ebed, 900: #fcf5f6
        ),
        'orchid_pink': (
                DEFAULT: #efb9cb,
                100: #451021, 200: #8a1f43, 300: #cf2f64, 400: #df7397, 500: #efb9cb, 600: #f2c6d5, 700: #f5d5e0, 800: #f9e3ea, 900: #fcf1f5
        ),
        'pale_dogwood': (
                DEFAULT: #e7cfcd,
                100: #3b1f1c, 200: #753d38, 300: #ae5e56, 400: #ca9691, 500: #e7cfcd, 600: #ebd7d6, 700: #f0e1e0, 800: #f5ebea, 900: #faf5f5
        ),
        'papaya_whip': (
                DEFAULT: #fdf0d5,
                100: #593c04, 200: #b17908, 300: #f5ae22, 400: #f9cf7b, 500: #fdf0d5, 600: #fdf2dc, 700: #fef5e5, 800: #fef9ed, 900: #fffcf6
        ),
        'platinum': (
                DEFAULT: #cfd8d7,
                100: #262f2e, 200: #4c5d5b, 300: #728c89, 400: #a1b2b0, 500: #cfd8d7, 600: #d9e0df, 700: #e2e8e7, 800: #ecefef, 900: #f5f7f7
        ),
        'plum': (
                DEFAULT: #e6adec,
                100: #3d1042, 200: #7a1f84, 300: #b72fc6, 400: #d16adc, 500: #e6adec, 600: #eabdef, 700: #f0cdf3, 800: #f5def7, 900: #faeefb
        ),
        'raspberry': (
                DEFAULT: #d81e5b,
                100: #2b0612, 200: #560c25, 300: #811237, 400: #ac1849, 500: #d81e5b, 600: #e6447a, 700: #ec739b, 800: #f2a2bd, 900: #f9d0de
        ),
        'reseda_green': (
                DEFAULT: #757761,
                100: #171813, 200: #2e2f27, 300: #46473a, 400: #5d5e4d, 500: #757761, 600: #93957d, 700: #aeaf9d, 800: #c9cabe, 900: #e4e4de
        ),
        'rose_quartz': (
                DEFAULT: #9f9aa4,
                100: #201e21, 200: #3f3c42, 300: #5f5a64, 400: #7e7885, 500: #9f9aa4, 600: #b1aeb5, 700: #c5c2c8, 800: #d8d6da, 900: #ecebed
        ),
        'thistle': (
                DEFAULT: #cab1bd,
                100: #2d1f26, 200: #5a3d4b, 300: #875c71, 400: #ab8396, 500: #cab1bd, 600: #d4c0ca, 700: #dfd0d7, 800: #eadfe4, 900: #f4eff2
        )
);

$themes: (
        standard: (
                background: (#ffffff, #121212),
                background_dark: (#f0f0f0, #1e1e1e),
                text: (#1a202c, #e0e0e0),
                reverse_text: (#e0e0e0, #e7e9ee),
                pill_text: (#007acc, #66bfff),
                text_dark: (#3d3d3d, #e0e0e0),
                highlight_text: (#00b248, #00e676),
                paragraph: (#47515e, #b3b3b3),
                border_color: (#b0bec5, #424242),
                active_border_color: (#157dcb, #2765a9),
                card_background: (#ffffff, #1e1e1e),
                input_background: (#fafafa, #2c2c2c),
                link_text: (#007acc, #66bfff),
                link_visited: (#005b9f, #448aff),
                link_border: (#bbdefb, #1565c0),
                em_text: (#250456, #9ea7e4),
                reverse_em_text: (#66bfff, #d3ecff),
                active_background: (#e3f2fd, #062b67),
                active_text: (#1a202c, #e0e0e0),
                primary_color: (#007acc, #2196f3),
                primary_text: (#ffffff, #ffffff),
                primary_border: (#005b9f, #97c9ff),
                ghost_color: (#44444411, #29346b59),
                ghost_text: (#276cff, #e0e0e0),
                ghost_border: (#2d87ff, rgba(95, 199, 255, 0.71)),
                danger_color: (#ef4444, #ee6a6a),
                danger_text: (#ffffff, #ffdbdb),
                danger_border: (#bb1515, #d32f2f),
                like_color: (#ffbbd5, #ee6a6a),
                like_text: (#ff1f1f, #ffdbdb),
                like_border: (#ef76b3, #d32f2f),
                warning_color: (#ffa000, #ffb74d),
                warning_text: (#ffffff, #ffffff),
                warning_border: (#ff6f00, #ffa000),
                disabled_background: (#f6f6f6, #252525),
                disabled_color: (#e0e0e0, #424242),
                disabled_text: (#9e9e9e, #bdbdbd),
                disabled_border: (#bdbdbd, #616161),
                comment_color: (#f5f5f5, #1e1e1e),
                comment_text: (#757575, #bdbdbd),
                comment_border: (#b0bec5, #424242),
                table_border: (#e0e0e0, #424242),
                table_th: (#f5f5f5, #1e1e1e),
                table_th_text: (#1a202c, #e0e0e0),
                table_td: (#ffffff, #2c2c2c),
                table_td_text: (#424242, #bdbdbd),
                modal_background: (rgba(255, 255, 255, 0.8), #1e1e1edd),
                modal_text: (#1a202c, #e0e0e0),
                tooltip_background: (#616161, #e0e0e0),
                tooltip_text: (#ffffff, #121212),
                announce_border: (#007acc, #66bfff),
                announce_background_stripe1: (#e3f2fd, #1e1e1e),
                announce_background_stripe2: (#f8ffff, #2c2c2c),
                skeleton_background: (#e0e0e0, #424242),
                hero_background: (#f5f5f5, #1e1e1e),
                hero_active_background: (#ffffff, #121212),
                hero_primary_background: (#18191a, #0d47a1),
        ),

        pirate: (
                background: (#F5DEB3, #2B1B0E), // Parchment, Dark Brown
                background_dark: (#EED9B9, #3E2A1F),
                text: (#2B1B0E, #F5DEB3), // Dark Brown, Parchment
                reverse_text: (#F5DEB3, #EED9B9),
                pill_text: (#8B4513, #DAA520), // SaddleBrown, GoldenRod
                text_dark: (#4E342E, #F5DEB3),
                highlight_text: (#C19A6B, #FFD700), // Camel, Gold
                paragraph: (#6F4E37, #D2B48C), // Coffee, Tan
                border_color: (#D2B48C, #5D4037),
                card_background: (#FFF8DC, #3E2723), // Cornsilk, Dark Brown
                input_background: (#F5DEB3, #4E342E),
                link_text: (#8B0000, #FF4500), // DarkRed, OrangeRed
                link_visited: (#A52A2A, #FF6347), // Brown, Tomato
                link_border: (#D2691E, #FF8C00), // Chocolate, DarkOrange
                em_text: (#8B4513, #FFD700), // SaddleBrown, Gold
                reverse_em_text: (#FFD700, #FFF8DC), // Gold, Cornsilk
                active_background: (#EED9B9, #5D4037),
                active_text: (#2B1B0E, #F5DEB3),
                primary_color: (#8B4513, #A0522D), // SaddleBrown, Sienna
                primary_text: (#FFFFFF, #FFFFFF),
                primary_border: (#5A2E0F, #8B4513),
                ghost_color: (#D2B48C, #5D403766), // Tan, Dark Brown with transparency
                ghost_text: (#8B4513, #F5DEB3),
                ghost_border: (#A0522D, rgba(165, 42, 42, 0.7)),
                danger_color: (#B22222, #FF6347), // FireBrick, Tomato
                danger_text: (#FFFFFF, #FFFFFF),
                danger_border: (#8B0000, #B22222),
                like_color: (#C71585, #FF69B4), // MediumVioletRed, HotPink
                like_text: (#FFFFFF, #FFFFFF),
                like_border: (#8B008B, #C71585),
                warning_color: (#DAA520, #FFD700), // GoldenRod, Gold
                warning_text: (#000000, #000000),
                warning_border: (#B8860B, #DAA520),
                disabled_color: (#E6E6E6, #3A3A3A),
                disabled_text: (#A9A9A9, #D3D3D3),
                disabled_border: (#BDBDBD, #4F4F4F),
                comment_color: (#FFF8DC, #3E2723),
                comment_text: (#4E342E, #D2B48C),
                comment_border: (#D2B48C, #5D4037),
                table_border: (#E6E6E6, #3A3A3A),
                table_th: (#F5DEB3, #2B1B0E),
                table_th_text: (#2B1B0E, #F5DEB3),
                table_td: (#FFF8DC, #3E2723),
                table_td_text: (#4E342E, #D2B48C),
                modal_background: (#FFF8DCDD, #3E2723DD),
                modal_text: (#2B1B0E, #F5DEB3),
                tooltip_background: (#4E342E, #D2B48C),
                tooltip_text: (#FFFFFF, #2B1B0E),
                announce_border: (#8B4513, #DAA520),
                announce_background_stripe1: (#EED9B9, #3E2723),
                announce_background_stripe2: (#F5DEB3, #4E342E),
                skeleton_background: (#D2B48C, #5D4037),
                hero_background: (#F5DEB3, #3E2723),
                hero_active_background: (#FFF8DC, #2B1B0E),
                hero_primary_background: (#8B4513, #A0522D),
        )
);

@function theme-color($property) {
  @return var(--#{$property});
}

$theme: standard; // Default theme

:root {
  @each $property, $values in map-get($themes, $theme) {
    --#{$property}: #{nth($values, 1)};
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @each $property, $values in map-get($themes, $theme) {
      --#{$property}: #{nth($values, 2)};
    }
  }
}

// Apply theme based on body class
@mixin apply-theme($theme-name) {
  @each $property, $values in map-get($themes, $theme-name) {
    --#{$property}: #{nth($values, 1)};
    // --#{$property}-dark: #{nth($values, 2)};
  }

  @media (prefers-color-scheme: dark) {
    @each $property, $values in map-get($themes, $theme-name) {
      --#{$property}: var(--#{$property}-dark);
    }
  }
}

body.theme-standard {
  @include apply-theme(standard);
}

body.theme-pastel_lilac_pink_yellow {
  @include apply-theme(pastel_lilac_pink_yellow);
}

$breakpoints: (
        'xs': 0,
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xl': 1200px,
        'xls': 1800px
);

// Function to get a breakpoint value
@function breakpoint($key) {
  @return map-get($breakpoints, $key);
}

// Mixin to generate media queries
@mixin respond-to($breakpoint) {
  @if $breakpoint == 'xs' {
    @media (max-width: breakpoint('sm') - 1px) {
      @content;
    }
  } @else {
    $value: breakpoint($breakpoint);
    @if $value {
      @media (max-width: $value - 1px) {
        @content;
      }
    }
  }
}

// Generate hide classes for each breakpoint
@each $break, $size in $breakpoints {
  .hide-#{$break} {
    @include respond-to($break) {
      display: none;
    }
  }
}

@mixin generate-color-classes($color-map) {
  @each $color-name, $color-weights in $color-map {
    @each $weight, $color-value in $color-weights {
      .theme-background-#{$color-name}-#{$weight} {
        background: #{get-color($color-name, $weight)};
      }
      .theme-color-#{$color-name}-#{$weight} {
        color: #{get-color($color-name, $weight)};
      }
    }
  }
}

// Usage of the mixin
@include generate-color-classes($COLORDEF);

* {
  box-sizing: border-box;
  letter-spacing: 0.052em;
  position: relative;
  padding: 0;
  margin: 0;
}